/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import logo_myworkspace from "../../../assets/images/my-workspace.png";
import LoadingButton from "@mui/lab/LoadingButton";
import login_image from "../../../assets/images/LoginImageNew.png";
// import logo_main from "../../../assets/images/login-image.png";
import "./login.css";
import { loginUser } from "../../../redux/actions/SuperAdmin/userActions";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { UseCompanyName } from "../../utils/hooks";
import { API_IMAGE } from "../../../redux/api";
import WestIcon from "@mui/icons-material/West";
import OTPComponent from "../otpInput";
import Spinner from "../../spinner";
import {
  login,
  resendOtp,
  verifyOtp,
  verifyOtpMws,
} from "../../../async/access/auth";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Login(props) {
  const queryHash = useQuery();
  const history = useHistory();
  const { pathname } = useLocation();
  const location = useLocation();
  const type = localStorage.getItem("type");
  const PKSClient = localStorage.getItem("clients");
  const hash = queryHash.get("k");
  // const location = useLocation();
  // const hashLink = location?.hash;
  // const getCompany = UseCompanyName();
  const dispatch = useDispatch();
  const [OTP, setOTP] = useState(null);
  const [showOtp, setShowOtp] = useState(false);
  const [error, setError] = useState(null);
  const [otpTimer, setOtpTimer] = useState(0);
  const [circularValue, setCircularValue] = useState(100);
  const [isError, setIsError] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const [sendOtpLoading, setSendOtpLoading] = useState(false);
  const [isHash, setIsHash] = useState("");
  const [hashPhoneNumber, setHashPhoneNumber] = useState();
  const [onLogin, setOnlogin] = useState(false);
  const [dataHash, setDataHash] = useState();
  const [status, setStatus] = useState("");
  const [errorStatus, setErrorStatus] = useState("");
  const [onResendOtp, setOnResendOtp] = useState(false);
  const [isType, setIsType] = useState();
  // const { isError } = useSelector((state) => state.user)
  const [values, setValues] = useState({
    employeeId: "",
    password: "",
    showPassword: false,
    error: false,
  });
  const user = useSelector((state) => state.user);
  const LoginImage = `${API_IMAGE}1683535495307-WhatsApp%20Image%202023-05-08%20at%203.15.01%20PM.jpeg-jpeg`;
  const [loginType, setLoginType] = useState("");

  const isClientHavePks = useMemo(() => {
    return localStorage.getItem("clients");
  }, []);

  useEffect(() => {
    if (hash) {
      setShowOtp(true);
    }
  }, [hash]);

  useEffect(() => {}, [hash]);

  useEffect(() => {
    setOtpTimer(60);
  }, [showOtp]);
  // console.log(isClientHavePks, 'client pks ')

  // useEffect(() => {
  //   if ( user?.bio?.type === "SUPER") {
  //     history.push("/superadmin");
  //   } else if (user?.authenticated && user?.bio?.type === "NORMAL") {
  //     history.push("/admin/employee-external");
  //   } else if (user?.authenticated && user?.bio?.type === "MIS") {
  //     history.push("/admin-mws/salary-advance");
  //   } else if (user?.authenticated && user?.bio?.type === "FMCG") {
  //     history.push("/admin-fmcg");
  //   } else {
  //     history.push("/");
  //   }
  // }, [user, history]);

  // useEffect(() => {
  //   if (user?.authenticated && user?.bio?.type === "SUPER" ) {
  //     history.push("/superadmin");
  //   } else if (user?.authenticated && user?.bio?.type === "NORMAL" && isClientHavePks) {
  //     history.push("/admin/employee-external");
  //   } else if (user?.authenticated && user?.bio?.type === "MIS") {
  //     history.push("/admin-mws/salary-advance");
  //   } else if (user?.authenticated && user?.bio?.type === "FMCG") {
  //     history.push("/admin-fmcg");
  //   } else {
  //     history.push("/");
  //   }
  // }, [user, history, isClientHavePks]);

  const handleLogin = async (e) => {
    let nrkWithZeroAtFirst = values?.employeeId?.charAt(0) === "0";
    if (nrkWithZeroAtFirst === true) {
      try {
        e.preventDefault();
        setSendOtpLoading(true);
        setOnlogin(true);
        const { response } = await login({
          employeeId: values?.employeeId?.slice(1),
        });
        if (response) {
          setSendOtpLoading(false);
          setOnlogin(false);
          history.push(`${pathname}?k=${response?.hash}`);
          setIsHash(response?.hash);
          setHashPhoneNumber(response?.phoneNumber);
        }
      } catch (error) {
        console.log(error, "error");
        if (
          error.response.data.error === "[NETWORK ERROR] Initial Server Error"
        ) {
          setIsError("Invalid Nrk/Username");
          setOnlogin(false);
        }
      }
    } else {
      try {
        e.preventDefault();
        setSendOtpLoading(true);
        setOnlogin(true);
        const { response } = await login({ employeeId: values?.employeeId });
        if (response) {
          setSendOtpLoading(false);
          setOnlogin(false);
          history.push(`${pathname}?k=${response?.hash}`);
          setIsHash(response?.hash);
          setDataHash(response);
          // handleUpdateQuery();
          // setHash(response?.hash);
          setHashPhoneNumber(response?.phoneNumber);
        }
      } catch (error) {
        console.log(error, "error");
        if (
          error.response.data.error === "[NETWORK ERROR] Initial Server Error"
        ) {
          setIsError(
            "MyWorkspace sedang maintenance, mohon di coba kembali nanti"
          );
          setOnlogin(false);
        } else if (
          error.response.data.error ===
          "[FORBIDDEN ERROR] Register a user first"
        ) {
          setIsError("Invalid data. Harap mendafarkan NRK");
          setOnlogin(false);
        } else if (
          error.response.data.error ===
          "[NOT FOUND] User is not registered on WhatsApp"
        ) {
          setIsError("Nomor tidak terdaftar pada WhatsApp");
          setOnlogin(false);
        } else if (
          error.response.data.error ===
          "[REQUEST ERROR] contract status ain't active. Check inputted data, route or params."
        ) {
          setIsError("NRK anda tidak aktif");
          setOnlogin(false);
        }
      }
    }

    // if (nrkWithZeroAtFirst === true) {
    //   dispatch(
    //     loginUser({
    //       employeeId: values.employeeId?.slice(1),
    //       password: values.password,
    //     })
    //   );

    //   setShowOtp(true);
    // } else {
    //   dispatch(
    //     loginUser({
    //       employeeId: values.employeeId,
    //       password: values.password
    //     })
    //   );
    //   setShowOtp(true);

    // }
  };

  const handleVerifyOtp = async (event) => {
    try {
      setLoginLoading(true);
      event.preventDefault();
      const data = await verifyOtp(hash, { code: OTP });
      setIsType(data?.response?.type);
      const isClientHavePks2 = data?.response?.clients?.[0]?.contractNumbers;
      // setIsPks(data?.response?.type);
      if (data.response.type === "SUPER" && data.response.isInternal === true) {
        localStorage.setItem("accessToken", data.response.accessToken);
        localStorage.setItem(
          "clientCode",
          data?.response?.clientCode?.[0]?.code
        );
        // localStorage.setItem("clientCode",data?.response?.clients[0]?.code);
        // localStorage.setItem("isSuper", data?.response?.isSuper);
        localStorage.setItem("isSuper", data?.response?.isInternal);
        localStorage.setItem(
          "clientName",
          data?.response?.clientCode?.[0]?.name
        );
        // localStorage.setItem("clientName",data?.response?.clients[0]?.name);
        localStorage.setItem("userName", data.response?.name);
        localStorage.setItem("type", data.response?.type);
        localStorage.setItem("joinDate", data.response?.joinDate);
        localStorage.setItem("salaryPayDay", data.response?.salaryPayDay);
        localStorage.setItem("employeeId", data.response?.nrk);
        // localStorage.setItem("clients",JSON.stringify(data.response?.clients));
        // localStorage.setItem("clientPks",data.response?.clients[0]?.contractNumbers);
      } else if (
        data.response.type === "NORMAL" &&
        data.response.isInternal === true
      ) {
        localStorage.setItem("accessToken", data.response.accessToken);
        localStorage.setItem(
          "clientCode",
          data?.response?.clientCode?.[0]?.code
        );
        // localStorage.setItem("clientCode",data?.response?.clients[0]?.code);
        // localStorage.setItem("isSuper", data?.response?.isSuper);
        localStorage.setItem("isSuper", data?.response?.isInternal);
        localStorage.setItem(
          "clientName",
          data?.response?.clientCode?.[0]?.name
        );
        // localStorage.setItem("clientName",data?.response?.clients[0]?.name);
        localStorage.setItem("userName", data.response?.name);
        localStorage.setItem("type", data.response?.type);
        localStorage.setItem("joinDate", data.response?.joinDate);
        localStorage.setItem("salaryPayDay", data.response?.salaryPayDay);
        localStorage.setItem("employeeId", data.response?.nrk);
        // localStorage.setItem("clients",JSON.stringify(data.response?.clients));
        // localStorage.setItem("clientPks",data.response?.clients[0]?.contractNumbers);
      } else if (
        data.response.type === "MIS" &&
        data.response.isInternal === true
      ) {
        localStorage.setItem("accessToken", data.response.accessToken);
        localStorage.setItem(
          "clientCode",
          data?.response?.clientCode?.[0]?.code
        );
        // localStorage.setItem("clientCode",data?.response?.clients[0]?.code);
        // localStorage.setItem("isSuper", data?.response?.isSuper);
        localStorage.setItem("isSuper", data?.response?.isInternal);
        localStorage.setItem(
          "clientName",
          data?.response?.clientCode?.[0]?.name
        );
        // localStorage.setItem("clientName",data?.response?.clients[0]?.name);
        localStorage.setItem("userName", data.response?.name);
        localStorage.setItem("type", data.response?.type);
        localStorage.setItem("joinDate", data.response?.joinDate);
        localStorage.setItem("salaryPayDay", data.response?.salaryPayDay);
        localStorage.setItem("employeeId", data.response?.nrk);
        // localStorage.setItem("clients",JSON.stringify(data.response?.clients));
        // localStorage.setItem("clientPks",data.response?.clients[0]?.contractNumbers);
      } else {
        localStorage.setItem("accessToken", data.response.accessToken);
        // localStorage.setItem("clientCode", data?.response?.clientCode?.[0]?.code);
        localStorage.setItem("clientCode", data?.response?.clients[0]?.code);
        // localStorage.setItem("isSuper", data?.response?.isSuper);
        localStorage.setItem("isSuper", data?.response?.isInternal);
        // localStorage.setItem("clientName", data?.response?.clientCode?.[0]?.name);
        localStorage.setItem("clientName", data?.response?.clients[0]?.name);
        localStorage.setItem("userName", data.response?.name);
        localStorage.setItem("type", data.response?.type);
        localStorage.setItem("clients", JSON.stringify(data.response?.clients));
        localStorage.setItem(
          "clientPks",
          data.response?.clients[0]?.contractNumbers
        );
        localStorage.setItem("joinDate", data.response?.joinDate);
        localStorage.setItem("salaryPayDay", data.response?.salaryPayDay);
        localStorage.setItem("employeeId", data.response?.nrk);
      }
      if (data) {
        setLoginLoading(false);
      }
      if (data.response.type === "SUPER" && data.response.isInternal === true) {
        history.push("/superadmin");
      } else if (
        data.response.type === "NORMAL" &&
        data.response.isInternal === true
      ) {
        history.push("/admin/employee-external");
      } else if (
        data.response.type === "NORMAL" &&
        isClientHavePks2 &&
        data.response.isInternal === false
      ) {
        history.push("/admin/employee-external");
      } else if (data.response.type === "MIS") {
        history.push("/admin-mws/salary-advance");
      } else if (data.response.type === "FMCG") {
        history.push("/admin-fmcg");
      } else {
        history.push("/");
      }
      // console.log(data);
    } catch (error) {
      setLoginLoading(false);
      if (error.response.data.error === "[AUTH ERROR] Wrong code input.") {
        setStatus("RepeatedOTP");
        setErrorStatus("Nomor OTP Salah");
      } else if (
        error.response.data.error ===
        "[REQUEST ERROR] Fill All Field. Check inputted data, route or params."
      ) {
        setStatus("RepeatedOTP");
        setErrorStatus("Masukkan OTP");
      } else {
        setStatus("RepeatedOTP");
        setErrorStatus("Kode OTP Kadaluwarsa");
      }
      console.log(error?.response?.data?.error, "error");
    }
  };

  const handleVerifyOtpMws = async (event) => {
    try {
      setLoginLoading(true);
      event.preventDefault();
      const data = await verifyOtpMws(OTP);
      setIsType(data?.response?.type);
      const isClientHavePks2 = data?.response?.clients?.[0]?.contractNumbers;
      // setIsPks(data?.response?.type);
      if (data.response.type === "SUPER" && data.response.isInternal === true) {
        localStorage.setItem("accessToken", data.response.accessToken);
        localStorage.setItem(
          "clientCode",
          data?.response?.clientCode?.[0]?.code
        );
        // localStorage.setItem("clientCode",data?.response?.clients[0]?.code);
        // localStorage.setItem("isSuper", data?.response?.isSuper);
        localStorage.setItem("isSuper", data?.response?.isInternal);
        localStorage.setItem(
          "clientName",
          data?.response?.clientCode?.[0]?.name
        );
        // localStorage.setItem("clientName",data?.response?.clients[0]?.name);
        localStorage.setItem("userName", data.response?.name);
        localStorage.setItem("type", data.response?.type);
        localStorage.setItem("joinDate", data.response?.joinDate);
        localStorage.setItem("salaryPayDay", data.response?.salaryPayDay);
        localStorage.setItem("employeeId", data.response?.nrk);
        // localStorage.setItem("clients",JSON.stringify(data.response?.clients));
        // localStorage.setItem("clientPks",data.response?.clients[0]?.contractNumbers);
      } else if (
        data.response.type === "NORMAL" &&
        data.response.isInternal === true
      ) {
        localStorage.setItem("accessToken", data.response.accessToken);
        localStorage.setItem(
          "clientCode",
          data?.response?.clientCode?.[0]?.code
        );
        // localStorage.setItem("clientCode",data?.response?.clients[0]?.code);
        // localStorage.setItem("isSuper", data?.response?.isSuper);
        localStorage.setItem("isSuper", data?.response?.isInternal);
        localStorage.setItem(
          "clientName",
          data?.response?.clientCode?.[0]?.name
        );
        // localStorage.setItem("clientName",data?.response?.clients[0]?.name);
        localStorage.setItem("userName", data.response?.name);
        localStorage.setItem("type", data.response?.type);
        localStorage.setItem("joinDate", data.response?.joinDate);
        localStorage.setItem("salaryPayDay", data.response?.salaryPayDay);
        localStorage.setItem("employeeId", data.response?.nrk);
        // localStorage.setItem("clients",JSON.stringify(data.response?.clients));
        // localStorage.setItem("clientPks",data.response?.clients[0]?.contractNumbers);
      } else if (
        data.response.type === "MIS" &&
        data.response.isInternal === true
      ) {
        localStorage.setItem("accessToken", data.response.accessToken);
        localStorage.setItem(
          "clientCode",
          data?.response?.clientCode?.[0]?.code
        );
        // localStorage.setItem("clientCode",data?.response?.clients[0]?.code);
        // localStorage.setItem("isSuper", data?.response?.isSuper);
        localStorage.setItem("isSuper", data?.response?.isInternal);
        localStorage.setItem(
          "clientName",
          data?.response?.clientCode?.[0]?.name
        );
        // localStorage.setItem("clientName",data?.response?.clients[0]?.name);
        localStorage.setItem("userName", data.response?.name);
        localStorage.setItem("type", data.response?.type);
        localStorage.setItem("joinDate", data.response?.joinDate);
        localStorage.setItem("salaryPayDay", data.response?.salaryPayDay);
        localStorage.setItem("employeeId", data.response?.nrk);
        // localStorage.setItem("clients",JSON.stringify(data.response?.clients));
        // localStorage.setItem("clientPks",data.response?.clients[0]?.contractNumbers);
      } else {
        localStorage.setItem("accessToken", data.response.accessToken);
        // localStorage.setItem("clientCode", data?.response?.clientCode?.[0]?.code);
        localStorage.setItem("clientCode", data?.response?.clients[0]?.code);
        // localStorage.setItem("isSuper", data?.response?.isSuper);
        localStorage.setItem("isSuper", data?.response?.isInternal);
        // localStorage.setItem("clientName", data?.response?.clientCode?.[0]?.name);
        localStorage.setItem("clientName", data?.response?.clients[0]?.name);
        localStorage.setItem("userName", data.response?.name);
        localStorage.setItem("type", data.response?.type);
        localStorage.setItem("clients", JSON.stringify(data.response?.clients));
        localStorage.setItem(
          "clientPks",
          data.response?.clients[0]?.contractNumbers
        );
        localStorage.setItem("joinDate", data.response?.joinDate);
        localStorage.setItem("salaryPayDay", data.response?.salaryPayDay);
        localStorage.setItem("employeeId", data.response?.nrk);
      }
      if (data) {
        setLoginLoading(false);
      }
      if (data.response.type === "SUPER" && data.response.isInternal === true) {
        history.push("/superadmin");
      } else if (
        data.response.type === "NORMAL" &&
        data.response.isInternal === true
      ) {
        history.push("/admin/employee-external");
      } else if (
        data.response.type === "NORMAL" &&
        isClientHavePks2 &&
        data.response.isInternal === false
      ) {
        history.push("/admin/employee-external");
      } else if (data.response.type === "MIS") {
        history.push("/admin-mws/salary-advance");
      } else if (data.response.type === "FMCG") {
        history.push("/admin-fmcg");
      } else {
        history.push("/");
      }
      // console.log(data);
    } catch (error) {
      setLoginLoading(false);
      if (error.response.data.error === "[AUTH ERROR] Wrong code input.") {
        setStatus("RepeatedOTP");
        setErrorStatus("Nomor OTP Salah");
      } else if (
        error.response.data.error ===
        "[REQUEST ERROR] Fill All Field. Check inputted data, route or params."
      ) {
        setStatus("RepeatedOTP");
        setErrorStatus("Masukkan OTP");
      } else {
        setStatus("RepeatedOTP");
        setErrorStatus("Kode OTP Kadaluwarsa");
      }
      console.log(error?.response?.data?.error, "error");
    }
  };

  // const handleConfirm = (event) => {
  //   event.preventDefault();
  //   if (hash) {
  //     handleVerifyOtp(event);
  //   } else {
  //     handleVerifyOtpMws(event);
  //   }
  // };

  const handleResendOtp = async (event) => {
    try {
      setOTP(null);
      event.preventDefault();
      const { response } = await resendOtp(hash);
      // console.log(response, 'responsneee')
      setOnResendOtp(false);
      setOtpTimer(60);
      history.push(`${pathname}?k=${response?.hash}`);
    } catch (error) {
      console.log(error);
      setOnResendOtp(false);
    }
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //   useEffect(() => {
  //   if ( isType === "SUPER" ) {
  //     history.push("/superadmin");
  //   } else if ( isType === "NORMAL" && isClientHavePks) {
  //     history.push("/admin/employee-external");
  //   } else if ( isType === "MIS") {
  //     history.push("/admin-mws/salary-advance");
  //   } else if ( isType === "FMCG") {
  //     history.push("/admin-fmcg");
  //   } else {
  //     history.push("/");
  //   }
  // }, [isType, history, isClientHavePks]);

  useEffect(() => {
    let otpInterval;
    otpInterval = setInterval(() => {
      setOtpTimer((prev) => (prev > 0 ? prev - 1 : prev));
    }, 1000);
    return () => clearInterval(otpInterval);
  }, []);

  useEffect(() => {
    setCircularValue((otpTimer / 60) * 100);
    // console.log(otpTimer, "gggg");
  }, [otpTimer]);

  const secondsToTime = (secs) => {
    // var hours = Math.floor(secs / (60 * 60));
    var divisor_for_minutes = secs % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);
    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = Math.ceil(divisor_for_seconds);
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    return minutes + ":" + seconds;
  };

  const parsedPhoneNumber = useMemo(() => {
    return hashPhoneNumber?.toString();
  }, [hashPhoneNumber]);

  const filler = useMemo(() => {
    const arr = [];
    for (let i = 3; i < parsedPhoneNumber?.length - 3; i++) {
      arr.push("*");
    }
    return arr;
  }, [parsedPhoneNumber]);

  return (
    <div className="container-login">
      <div className="wrapper-container-login">
        <div className="login-container-left">
          <div className="wrapper-logo-login">
            <img src={logo_myworkspace} alt="" />
            <span>MyWorkSpace</span>
          </div>
          <div className="wrapper-image-login">
            <img src={LoginImage} alt="Login_Image" />
          </div>
        </div>

        {showOtp && loginType === "" ? (
          <form
            className="login-container-right-otp"
            onSubmit={handleVerifyOtp}
          >
            <div
              className="back-button"
              onClick={() => {
                setShowOtp(false);
                setLoginType("");
              }}
            >
              <WestIcon />
              <span>Back</span>
            </div>
            <div className="wrapper-otp-text">
              <span>OTP Confirmation</span>
              <span>
                {"Enter the OTP code we sent to your registered phone number "}
                {parsedPhoneNumber?.slice(0, 3)}
                {filler?.map((each, i) => {
                  return <span key={i}>*</span>;
                })}
                {parsedPhoneNumber?.slice(parsedPhoneNumber?.length - 3)}
              </span>
            </div>
            <div className="otp-wrapper">
              <OTPComponent
                onChange={(value) => {
                  setOTP(value);
                }}
                alert={error}
              />
            </div>

            <div className="request-text-container">
              <div className="request-text" onClick={handleResendOtp}>
                <span>Request again ?</span>
              </div>
              <div className="request-timer">{secondsToTime(otpTimer)}</div>
            </div>
            {status === "RepeatedOTP" ? (
              <div className="invalid-number">* {errorStatus}</div>
            ) : null}
            <div className="button-wrapper">
              <Button
                style={{
                  textTransform: "none",
                  backgroundColor: "#1571de",
                  color: "white",
                  width: "100%",
                  height: 44,
                  borderRadius: 5,
                  fontSize: 12,
                  fontWeight: 500,
                }}
                disabled={!OTP}
                type="submit"
                // onClick={handleVerifyOtp}
              >
                Confirmation
              </Button>
            </div>
            <div className="container-privacy">
              <h4>
                Myworkspace •{" "}
                <span onClick={() => history.push(`/privacy-and-policy`)}>
                  Kebijakan Privasi
                </span>
              </h4>
            </div>
          </form>
        ) : showOtp && loginType === "mws" ? (
          <form
            className="login-container-right-otp"
            onSubmit={handleVerifyOtpMws}
          >
            <div
              className="back-button"
              onClick={() => {
                setShowOtp(false);
                setLoginType("");
              }}
            >
              <WestIcon />
              <span>Back</span>
            </div>
            <div className="wrapper-otp-text">
              <span>OTP Confirmation</span>
              <span>
                {"Enter the OTP code we sent to your registered phone number "}
                {parsedPhoneNumber?.slice(0, 3)}
                {filler?.map((each, i) => {
                  return <span key={i}>*</span>;
                })}
                {parsedPhoneNumber?.slice(parsedPhoneNumber?.length - 3)}
              </span>
            </div>
            <div className="otp-wrapper">
              <OTPComponent
                onChange={(value) => {
                  setOTP(value);
                }}
                alert={error}
              />
            </div>
            {status === "RepeatedOTP" ? (
              <div className="invalid-number">* {errorStatus}</div>
            ) : null}
            <div className="button-wrapper">
              <Button
                style={{
                  textTransform: "none",
                  backgroundColor: "#1571de",
                  color: "white",
                  width: "100%",
                  height: 44,
                  borderRadius: 5,
                  fontSize: 12,
                  fontWeight: 500,
                }}
                disabled={!OTP}
                type="submit"
                // onClick={handleVerifyOtp}
              >
                Confirmation
              </Button>
            </div>
            <div className="container-privacy">
              <h4>
                Myworkspace •{" "}
                <span onClick={() => history.push(`/privacy-and-policy`)}>
                  Kebijakan Privasi
                </span>
              </h4>
            </div>
          </form>
        ) : (
          <div className="login-container-right">
            <div className="wrapper-text-login">
              <h2>Welcome to MyWorkSpace!&nbsp;👋</h2>
              <p>Please sign-in to your account, and start manage further</p>
            </div>
            <form onSubmit={handleLogin} className="wrapper-login-form">
              <div className="form-control-login">
                <span>NRK</span>
                <OutlinedInput
                  placeholder="Your registered username"
                  type={values.employeeId}
                  value={values.employeeId}
                  onChange={handleChange("employeeId")}
                  sx={{
                    height: 44,
                    fontSize: 12,
                    fontWeight: 400,
                    color: "#000000",
                  }}
                />
                {isError && <div className="invalid-number"> * {isError}</div>}
              </div>
              {/* <div className="form-control-login">
              <span>Password</span>
              <OutlinedInput
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                placeholder="•••••••••"
                sx={{
                  height: 44,
                  fontSize: 12,
                  fontWeight: 400,
                  color: "#000000",
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div> */}
              <div className="form-control-button">
                <Button
                  className={
                    !values?.employeeId ? "disabled-button" : "default-button "
                  }
                  type="submit"
                  loading={loginLoading || {}}
                  disabled={!values.employeeId}
                >
                  {loginLoading ? <Spinner /> : "Sign In"}
                </Button>
                <p>Or</p>
                <Button
                  style={{
                    textTransform: "none",
                    border: "1px solid #1571DE",
                    color: "#1571DE",
                    width: "100%",
                    height: 44,
                    borderRadius: 5,
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                  // type="submit"
                  onClick={() => {
                    setShowOtp(true);
                    setLoginType("mws");
                  }}
                >
                  <img
                    src={logo_myworkspace}
                    alt=""
                    style={{
                      width: "24px",
                      height: "20px",
                      marginRight: "10px",
                    }}
                  />
                  Login Using MWS Mobile
                </Button>
              </div>
            </form>
            <div className="container-privacy">
              <h4>
                Myworkspace •{" "}
                <span onClick={() => history.push(`/privacy-and-policy`)}>
                  Kebijakan Privasi
                </span>
              </h4>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Login;
