import React, { useEffect, useState } from "react";
import { Button, Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import Styles from "./LeaveSchema.module.css";
import SearchbarSA from "../../../components/utils/SearchbarSA/SearchbarSA";
import { Add, ArrowRightAlt } from "@mui/icons-material";
import LoadingAnimation from "../../../components/utils/LoadingAnimation/LoadingAnimation";
import fetchLeavePlan from "../../../async/leavePlan/fetchLeavePlan";
import {
  AddLeaveModal,
  DeleteLeaveModal,
  LeaveDetailModal,
} from "../../../components/SuperAdmin/_leaveSchema";
import createLeavePlan from "../../../async/leavePlan/createLeavePlan";
import editLeavePlan from "../../../async/leavePlan/editLeavePlan";
import deleteLeavePlan from "../../../async/leavePlan/deleteLeavePlan";
import { useHistory } from "react-router-dom";

export default function LeaveSchema() {
  const history = useHistory();
  const token = localStorage.getItem("accessToken");
  const [leavePlan, setLeavePlan] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [listLeaves, setListLeaves] = useState("");
  const [dataToSend, setDataToSend] = useState({
    dayoff: "",
    startPeriod: "January",
    endPeriod: "",
    nextYear: true,
  });
  const [selectedData, setSelectedData] = useState({
    dayoff: "",
    startPeriod: "January",
    endPeriod: "",
    nextYear: false,
  });

  const handleSearch = (event) => {
    setListLeaves(event.target.value);
  };

  const handleGetData = async () => {
    try {
      setLoading(true);
      const data = await fetchLeavePlan(token);
      setLeavePlan(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateLeavePlan = async (datas) => {
    try {
      setLoading(true);
      await createLeavePlan(token, datas);
      handleGetData();
      setLoading(false);
      setDataToSend({
        dayoff: "",
        startPeriod: "January",
        endPeriod: "",
        nextYear: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditLeavePlan = async (datas) => {
    try {
      setLoading(true);
      await editLeavePlan(token, datas, datas.id);
      handleGetData();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteLeavePlan = async (datas) => {
    try {
      setLoading(true);
      await deleteLeavePlan(token, datas.id);
      handleGetData();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | Leave Schema</title>
      </Helmet>
      <div className="wrapper-client-SA">
        <h2
          className="page-header"
          style={{ fontWeight: 500, color: "#0A0A0A", fontSize: 40 }}
        >
          Leave Schema
        </h2>
      </div>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <div className={Styles.Container}>
            <div className={Styles.Header}>
              <div className={Styles.Searchbar}>
                <SearchbarSA
                  value={listLeaves}
                  onChange={handleSearch}
                  // onSubmit={handleSubmitFilter}
                  searchWidth="379px"
                  placeholder="Search by name"
                />
              </div>
              <div className={Styles.ButtonAddAttendance}>
                <Button
                  startIcon={<Add style={{ color: "white", fontSize: 16 }} />}
                  className={Styles.AddButton}
                  // onClick={() => setOpenModal("add")}
                  onClick={() => history.push(`/superadmin/add-leave-scheme`)}
                >
                  <span>Add Scheme</span>
                </Button>
              </div>
            </div>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 50,
                }}
              >
                <div style={{ width: 200 }}>
                  <LoadingAnimation />
                </div>
              </div>
            ) : (
              <div className={Styles.WrapperCard}>
                {leavePlan
                  .filter((el) =>
                    el.name.toLowerCase().includes(listLeaves.toLowerCase())
                  )
                  .map((el, idx) => (
                    <div className={Styles.Card} key={idx}>
                      <div className={Styles.CardHeader}>
                        <span className={Styles.CardHeaderIcon}>
                          <i className="bx bx-calendar-star"></i>
                        </span>
                        <span className={Styles.CardHeaderTitle}>
                          {/* Schema {idx + 1} */}
                          {el.name}
                        </span>
                      </div>
                      <div className={Styles.CardBody}>
                        <span>{`${el.periodStart} - ${el.periodNext}`}</span>
                      </div>
                      <div className={Styles.CardDetail}>
                        <span>Total leave</span>
                        <span>{el.initialBalance} Day</span>
                      </div>
                      <div className={Styles.CardFooter}>
                        <div
                          className={Styles.CardFooterSetting}
                          // onClick={() => {
                          //   setOpenModal("detail");
                          //   setSelectedData({
                          //     dayoff: el.dayoff,
                          //     startPeriod: el.period.split("-")[0],
                          //     endPeriod: el.period.split("-")[1],
                          //     nextYear: el.remark === "NEXT",
                          //     id: el._id,
                          //   });
                          // }}
                          onClick={() => {
                            history.push({
                              pathname: `/superadmin/leave-scheme-view/${el._id}`,
                              // state: {
                              //   dataLoan: each,
                              // },
                            });
                          }}
                        >
                          <span>View Settings</span>
                          <ArrowRightAlt
                            style={{ color: "#1571DE", marginLeft: 8 }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
            <AddLeaveModal
              open={openModal === "add"}
              setOpen={setOpenModal}
              value={dataToSend}
              setValue={setDataToSend}
              handleSubmit={() => {
                handleCreateLeavePlan(dataToSend);
                setOpenModal(false);
              }}
            />
            <AddLeaveModal
              open={openModal === "edit"}
              setOpen={setOpenModal}
              value={selectedData}
              setValue={setSelectedData}
              handleSubmit={() => {
                handleEditLeavePlan(selectedData);
                setOpenModal(false);
              }}
            />
            <LeaveDetailModal
              open={openModal === "detail"}
              setOpen={setOpenModal}
              data={selectedData}
              isControlled
            />
            {openModal === "delete" && (
              <DeleteLeaveModal
                setOpen={setOpenModal}
                handleDelete={() => {
                  handleDeleteLeavePlan(selectedData);
                  setOpenModal(false);
                }}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
