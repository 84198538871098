/* eslint-disable no-unused-vars */
import {
    Autocomplete, Avatar, AvatarGroup, Grid, MenuItem, Select, Stack, styled, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import EDIT_ICON from "../../../../assets/images/create.png";
import fetchPositions from "../../../../async/groupData/fetchPositions";
import { fetchAllEmployees } from "../../../../redux/actions/SuperAdmin/employeesActions";
import {
    UseCompanyName
} from "../../../utils/hooks";
import LoadingAnimation from "../../../utils/LoadingAnimation/LoadingAnimation";
import SearchbarSA from "../../../utils/SearchbarSA/SearchbarSA";
import CustomTablePagination from "../../../_pagination/CustomTablePagination";
import Scrollbar from "../TableUsers/Scrollbar";
import Styles from './EmployeeTable.module.css';
import { API_IMAGE  } from "../../../../redux/api";
const MuiTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderRadius: 8,
        },
    },
});



const useStyles = makeStyles({
    avatar: {
        background: '#404040',
        fontSize: '14px',
        width: '24px',
        height: '24px',
    }
});



const TABLE_HEAD = [
    { id: "name", label: "Employee Name", alignRight: false },
    { id: "employeeId", label: "NRK", alignRight: false },
    { id: "remainAnnual", label: "Sisa Cuti", alignRight: false },
    { id: "jobTitle", label: "Position", alignRight: false },
    { id: "division", label: "Division", alignRight: false },
    { id: "subordinates", label: "Staff", alignRight: false },
    { id: "loanApprover", label: "Approver", alignRight: false },
    { id: "" },
];

const positions = [
    { id: 1, label: 'Product Manager' },
    { id: 2, label: 'Product Designer' },
    { id: 3, label: 'Sales Representative' },
]

// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};

const useDebouncedCallback = (func, wait) => {
    const timeout = useRef();

    return useCallback(
        (...args) => {
            const later = () => {
                clearTimeout(timeout.current);
                func(...args);
            };

            clearTimeout(timeout.current);
            timeout.current = setTimeout(later, wait);
        },
        [func, wait]
    );
};

function EnhancedTableHead(props) {
    const {
        order,
        orderBy,
        onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead
            sx={{
                "& tr": {
                    backgroundColor: "#F0F0F0",
                },
            }}
        >
            <TableRow>

                {TABLE_HEAD.map((headCell, index) => (
                    <TableCell
                        key={headCell.id + index}
                        align="left"
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default function EmployeeTable({
    loading,
    limit,
    setLimit,
    page,
    setPage,
    dataClientCodes,
    setDataClientCodes,
    selectedClient,
    setSelectedClient,
    clientName,
    name,
    setName,
    employees,
    totalPage,
    defaultClient,
}) {
    const getCompany = UseCompanyName();
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const token = localStorage.getItem("accessToken");
    const [order, setOrder] = useState("asc");
    const [selected, setSelected] = useState('');
    const [selectedInput, setSelectedInput] = useState("");
    const [selectedDropdown, setSelectedDropdown] = useState("");
    const [orderBy, setOrderBy] = useState("Name");
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [modalSettingsEmployee, setModalSettingsEmployee] = useState(false);
    const options = ["Position"];
    const [listEmployees, setListEmployees] = useState("");
    const [selectedGroup, setSelectedGroup] = useState("");
    const [typedDropdown, setTypedDropdown] = useState("");
    const [positions, setPositions] = useState([]);
    const [status, setStatus] = useState("all");
    // const [statusLeave, setStatusLeave] = useState("setLeave");
    const [position, setPosition] = useState("");

    const [pendingLoad, setPendingLoad] = useState(false);

    const persistName = useMemo(()=> {
        const temp = window.sessionStorage.getItem("persistName")
        if (temp) {
            return temp
        } else {
            return name
        }
    }, [name]);

    useEffect(()=> {
        setName(persistName)
    }, [persistName, setName])


    const persistClientName = useMemo(() => {
        let persistClientName = window.sessionStorage.getItem("selectedClientName")
        let clientName;

        if (persistClientName) {
            clientName = persistClientName
        } else {
            clientName = selectedClient.name;
        }

        return clientName;
    }, [selectedClient])

    const persistClientCode = useMemo(() => {
        let persistClientCode = window.sessionStorage.getItem("selectedClientCode")
        let clientCode;

        if (persistClientCode) {
            clientCode = persistClientCode
        } else {
            clientCode = selectedClient.codes;
        }

        return clientCode
    }, [selectedClient])

    const persistPosition = useMemo(() => {
        let persistPosition = window.sessionStorage.getItem("selectedPosition")
        let positionResult;

        if (persistPosition) {
            positionResult = persistPosition
        } else {
            positionResult = position
        }

        return positionResult
    }, [position])


    const [persistValue, setPersistValue] = useState({
        name: persistClientName,
        codes: persistClientCode
    })

    const persistListMode = useMemo(() => {
        let persistMode = window.sessionStorage.getItem('selectedListMode')
        let mode;
        if (persistMode) {
            mode = persistMode
        } else {
            mode = status
        }
        return mode
    }, [status])

    // const persistLeaveMode = useMemo(() => {
    //     let persistMode = window.sessionStorage.getItem('selectedLeaveMode')
    //     let mode;
    //     if (persistMode) {
    //         mode = persistMode
    //     } else {
    //         mode = statusLeave
    //     }
    //     return mode
    // }, [statusLeave])

    const query = `clientCode=${persistClientCode}&limit=${limit}&pageNumber=${page}&position=${persistPosition}&name=${persistName}&status=${persistListMode || ''}`;

    useEffect(() => {
        setStatus(persistListMode)
    }, [persistListMode])

    useEffect(() => {
        if (pendingLoad === false) {
            dispatch(fetchAllEmployees(token, query))
        }
    }, [token, dispatch, query, name, pendingLoad])

    useEffect(() => {
        (async () => {
            try {
                const { data } = await fetchPositions(token, persistClientName);
                setPositions(data?.position);
            } catch (error) {
                console.log(error);
            }
        })();
    }, [persistClientName, token]);



    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // const handleSearchInput = (event) => {
    //     setPendingLoad(true)
    //     setListEmployees(event.target.value)
    //     setTimeout(() => {
    //         setPendingLoad(false)
    //     }, 1000)
    //     clearTimeout()
    //     setName(event.target.value);
    // };


    const handleSubmitFilter = (e) => {
        e.preventDefault();
        setName(e.target.value);
    };


    const handleSelectedClient = (e, newVal) => {
        setPendingLoad(true)
        if (newVal !== null) {
            window.sessionStorage.setItem('selectedClientCode', newVal?.codes)
            window.sessionStorage.setItem('selectedClientName', newVal?.name)
            setSelectedClient(newVal)
            window.sessionStorage.setItem('selectedPosition', '')
            setPosition('')
        } else {
            setSelectedClient(selectedClient)
        }
        setTimeout(() => {
            setPendingLoad(false)
        }, 1000)
        clearTimeout()
    }

    const handleSetPosition = (e, newVal) => {
        setPendingLoad(true)
        if (newVal !== null) {
            window.sessionStorage.setItem('selectedPosition', newVal)
            setPosition(newVal)
        } else {
            window.sessionStorage.setItem('selectedPosition', '')
            setPosition('')
        }
        setTimeout(() => {
            setPendingLoad(false)
        }, 1000)
        clearTimeout()
    }

    useEffect(() => {
        setPosition(persistPosition)
    }, [persistPosition])

    const handleSearchName = (e) => {
        setPendingLoad(true)
        window.sessionStorage.setItem('persistName', e.target.value)
        setName(e.target.value)
        setTimeout(() => {
            setPendingLoad(false)
        }, 1000)
        clearTimeout()
    }

    const capitalizedClientName = (clientName) => {
        return clientName?.toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
            .replace('Pt', 'PT')
            .replace('Cv', 'CV')
    }
    const [list, setList] = useState('')
    const [statusPending, setStatusPending] = useState(false)

    const handleListStatus = (event) => {
        setList(event.target.value);
        window.sessionStorage.setItem('selectedListMode', event.target.value)
        setStatusPending(true)
        setStatus(event.target.value)
        // if (event.target.value === 'edit') {
        //     window.sessionStorage.setItem('selectedListMode', 'edit')
        //     setStatusPending(true)
        //     setStatus('edit')
        // } else if (event.target.value === 'inactive') {
        //     window.sessionStorage.setItem('selectedListMode', 'inactive')
        //     setStatusPending(true)
        //     setStatus('inactive')
        // }
        // else {
        //     window.sessionStorage.setItem('selectedListMode', 'all')
        //     setStatusPending(false)
        //     setStatus('all')
        // }
    };

    // const handleSetLeaveStatus = (event) => {
    //     setStatusLeave(event.target.value);
    //     window.sessionStorage.setItem('selectedLeaveMode', event.target.value)
    // }

    const handleSearch = () => {

    }

    return (
        <div className={Styles.Container}>
            <div className={Styles.Wrapper}>
                <div className={Styles.Searchbar}>
                    <SearchbarSA
                        // value={listEmployees}
                        value={persistName}
                        // onChange={handleSearchInput}
                        onChange={handleSearchName}
                        searchWidth="379px"
                        placeholder="Search"
                    />
                </div>
                <div className={Styles.Autocomplete}>
                    <Autocomplete
                        disablePortal
                        id="attendee"
                        disabled={loading}
                        defaultValue={persistValue}
                        getOptionLabel={(option) => option.name}
                        options={dataClientCodes}
                        onInputChange={(e, value) =>
                            value === null ? handleSearch("") : handleSearch(value)
                          }
                        onChange={(e, newVal) => newVal !== null ? handleSelectedClient(e, newVal) : handleSelectedClient(e, null)}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={option?.codes}>
                                    {option?.name}
                                </li>
                            );
                        }}
                        renderInput={(params) => <MuiTextField {...params} size="small" sx={{ width: 250, background: 'var(--main-bg)' }} />
                        }
                    />
                </div>

                <div className={Styles.Autocomplete}>
                    <Autocomplete
                        disablePortal
                        disabled={loading}
                        value={persistPosition || ""}
                        options={positions?.filter(
                            (each) => each !== null && each !== "NULL" && each !== ""
                          ) || []}
                        onInputChange={handleSetPosition}
                        onChange={handleSetPosition}
                        renderInput={(params) => (
                            <MuiTextField {...params} size="small" sx={{ width: 210, background: 'var(--main-bg)' }} placeholder="Position" />
                        )}
                    />
                </div>

                <div className={Styles.SelectBox}>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={persistListMode || ""}
                        onChange={handleListStatus}
                        className={Styles.Select}
                        disabled={loading}
                    >
                        <MenuItem value="all">All List</MenuItem>
                        <MenuItem value="PENDING">Edit Request List</MenuItem>
                        <MenuItem value="INACTIVE">Resigned</MenuItem>
                    </Select>
                </div>
                {/* <div className={Styles.SelectBox}>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={persistLeaveMode || ""}
                        onChange={handleSetLeaveStatus}
                        className={Styles.Select}
                        disabled={loading}
                    >
                        <MenuItem value="setLeave">Atur jatah cuti</MenuItem>
                        <MenuItem value="vendor">Karyawan Pindah Vendor</MenuItem>
                        <MenuItem value="leave">Jatah Cuti Karyawan</MenuItem>
                    </Select>
                </div> */}
            </div>
            <div className={Styles.SubHeader}>
                <span style={{ textTransform: 'capitalize' }}>All {capitalizedClientName(persistClientName)} Employees</span>
                <div className={Styles.EditIndicator}>
                <div className={ Styles.RequestEdit }>
                <div></div>
                    <span>Request Edit</span>
                </div>
                <div className={ Styles.Resign }>
                <div></div>
                    <span>Resigned</span>
                </div>
                </div>
            </div>
            {loading ? (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 100
                    }}
                >
                    <div style={{ width: 200 }}>
                        <LoadingAnimation />
                    </div>
                </div>
            ) : (
                <div className={Styles.TableWrapper}>
                    <Scrollbar>
                        <TableContainer className={Styles.TableContainer}>
                            <Table id="emp-table">
                                <colgroup>
                                    <col style={{ width: '30%%' }} />
                                    <col style={{ width: '15%' }} />
                                    <col style={{ width: '20%' }} />
                                    <col style={{ width: '20%' }} />
                                    <col style={{ width: '15%' }} />
                                    <col style={{ width: '10%' }} />
                                    <col style={{ width: '5%' }} />
                                    <col style={{ width: '5%' }} />
                                </colgroup>
                                <EnhancedTableHead
                                    numSelected={employees.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    values={employees}
                                />
                                <TableBody>
                                    {stableSort(employees, getComparator(order, orderBy))
                                        .filter(
                                            (el) =>
                                            /* (el?.name?.toLowerCase().includes(listEmployees?.toLowerCase()) || */
                                            /* el?.employeeId?.toLowerCase().includes(listEmployees?.toLowerCase())) && */
                                            (selectedDropdown.label ? el?.jobTitle.toLowerCase().includes(selectedDropdown?.label?.toLowerCase()) : true) &&
                                            {/* (typedDropdown ? el?.jobTitle.toLowerCase().includes(typedDropdown.toLowerCase()) : true) */}

                                        )
                                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((each, i) => {
                                            const labelId = `enhanced-table-checkbox-${i}`;
                                            return (
                                                <TableRow hover key={i} tabIndex={-1} id={labelId} style={{ background: each?.statusPending ? '#FFF9E9' : each?.status === "INACTIVE" ? "#FFF4F2" : '#fff' }}>
                                                    <TableCell align="left" component="th" scope="row">
                                                        <Link
                                                            to={{
                                                                pathname: `/superadmin/profile-employee/${each?.employeeId}`,
                                                                state: { data: each },
                                                            }}
                                                        >
                                                            <Stack
                                                                direction="row"
                                                                alignItems="center"
                                                                spacing={2}
                                                            >
                                                                <Avatar
                                                                    src={
                                                                        each?.profilePicture
                                                                            ? each.profilePicture
                                                                            : null
                                                                    }
                                                                    className={Styles.Avatar}
                                                                />
                                                                <Typography variant="subtitle2" noWrap sx={{ textTransform: 'capitalize' }}>
                                                                    {each.name ? each.name.toLowerCase() : "-"}
                                                                </Typography>
                                                            </Stack>
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
                                                        {each.employeeId ? each.employeeId.toLowerCase() : "-"}
                                                    </TableCell>
                                                    <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
                                                        {each.remainAnnual ? each.remainAnnual : 0}
                                                    </TableCell>
                                                    <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
                                                    { each?.jobTitle ? each?.jobTitle.toLowerCase() : "-"}
                                                        {/* {each.jobTitle ? each.jobTitle.toLowerCase().replace("it", "IT") : "-"} */}
                                                    </TableCell>
                                                    <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
                                                        {each?.division ? each?.division.toLowerCase() : "-"}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {each?.subordinates?.length !== 0 && (
                                                            <Grid container>
                                                                <Grid item xs={0}>
                                                                    <AvatarGroup key={i} max={4} classes={{ avatar: Styles.Avatar }}>
                                                                        {each?.subordinates?.map((sub, i) => (
                                                                            <Avatar key={i} className={Styles.Avatar}>{sub?.name?.charAt(0)}</Avatar>
                                                                        ))}
                                                                    </AvatarGroup>

                                                                </Grid>
                                                            </Grid>
                                                        )}

                                                        {each?.subordinates?.length === 0 && (
                                                            <span>-</span>
                                                        )}

                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Grid container>
                                                            {each?.loanApprovers?.length > 0 && (
                                                                <Grid container>
                                                                    <Grid item xs={0}>
                                                                        <AvatarGroup key={i} max={4} classes={{ avatar: Styles.Avatar }}>
                                                                            {each?.loanApprovers?.map((sub, i) => (
                                                                                <Avatar key={i} src={sub?.profilePicture ? sub?.profilePicture?.replace(`https://myworkspace.id/api/image/`, `${API_IMAGE}`): '/assets/images/logo-user.png'} />
                                                                            ))}
                                                                        </AvatarGroup>

                                                                    </Grid>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Link
                                                            to={{
                                                                pathname: `/superadmin/edit-employee/${each.employeeId}`,
                                                                state: { data: each },
                                                            }}
                                                        >
                                                            <img src={EDIT_ICON} className={Styles.EditIcon} alt="edit" />
                                                        </Link>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                    <CustomTablePagination
                        limit={limit}
                        setLimit={setLimit}
                        page={page}
                        setPage={setPage}
                        totalPage={totalPage}
                    />
                </div>
            )
            }
        </div>
    );
}
