/* eslint-disable no-unused-vars */
import Styles from './AttendanceEditSchemeMenu.module.css'
import { AccessTime } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import DELETE_ICON from "../../../../assets/images/delete-rules-icon.png";
import { Button, IconButton, Switch, TextField } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { updateAttendanceById } from "../../../../redux/actions/SuperAdmin/attendancesActions";
import ModalAttendanceSASuccess from "../../_client/ModalAttendandanceSASuccess/ModalAttendanceSASuccess";
import "./AttendanceEditSchemeMenu.css";

export default function AttendanceEditSchemeMenu({ attendanceData }) {
  const token = localStorage.getItem("accessToken");
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams()
  const [selectedSchema, setSelectedSchema] = useState([]);
  const [name, setName] = useState("")
  const [remark, setRemark] = useState("")
  const [workingHours, setworkingHours] = useState([]);
  const [overtime, setOvertime] = useState(false);
  const [overtimeStart, setOvertimeStart] = useState("");
  const [lockLocation, setLockLocation] = useState(false);
  const [notifyEmail, setNotifyEmail] = useState(false);
  const [wfh, setWfh] = useState(false);
  const [cutOffFrom, setCutOffFrom] = useState("");
  const [cutOffTo, setCutOffTo] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    setSelectedSchema(attendanceData)
  }, [attendanceData])

  useEffect(() => {
    setName(selectedSchema?.name)
    setRemark(selectedSchema?.remark)
    setworkingHours(selectedSchema?.workHours)
    setOvertime(selectedSchema?.overtime?.eligible || false)
    setOvertimeStart(selectedSchema?.overtime?.startAfterWorkInMin)
    setLockLocation(selectedSchema?.isLockedLocation || false)
    setNotifyEmail(selectedSchema?.isLockedLocation || false)
    setWfh(selectedSchema?.canWorkFromHome || false)
    setCutOffFrom(selectedSchema?.cutOff?.startDate)
    setCutOffTo(selectedSchema?.cutOff?.endDate)
  }, [selectedSchema])

  const entryHour = (timeString) => {
    const today = new Date();
    const arrEntryHour = timeString.split(":");
    today.setUTCHours(
      +arrEntryHour[0] - 7,
      +arrEntryHour[1],
      +arrEntryHour[2],
      0
    );
    return today;
  };

  const handleAddWorkingHour = () => {
    const newWorkingHours = [
      ...workingHours,
      {
        isShiftHour: false,
        startTime: "08:00:00",
        workInMinute: 480,
        breakDurationInMinute: 60
      }
    ]
    setworkingHours(newWorkingHours)
  }

  const handleDeleteWorkingHour = (index) => {
    const newWorkingHours = workingHours.filter((_, i) => i !== index)
    setworkingHours(newWorkingHours);
  }

  const handleChangeStartTime = (newValue, index) => {
    const newWorkingHours = [...workingHours];
    newWorkingHours[index].startTime = new Date(newValue).toLocaleTimeString("en", { hour12: false });
    setworkingHours(newWorkingHours);
  }

  const handleIsShift = (event, index) => {
    const newWorkingHours = [...workingHours];
    newWorkingHours[index].isShiftHour = Boolean(event.target.value === "shift" ? true : false);
    setworkingHours(newWorkingHours);
  }

  const handleChangeWorkingMinutes = (event, index) => {
    const newWorkingHours = [...workingHours];
    newWorkingHours[index].workInMinute = Number(event.target.value);
    setworkingHours(newWorkingHours);
  }

  const handleChangeBreakDuration = (event, index) => {
    const newWorkingHours = [...workingHours];
    newWorkingHours[index].breakDurationInMinute = Number(event.target.value);
    setworkingHours(newWorkingHours);
  }

  const routeToAttendance = () => {
    let path = "/superadmin/attendance";
    history.push(path);
  };

  const handleChangeOvertime = (event) => {
    setOvertime(event.target.checked);
  };

  const handleChangeLockLocation = (event) => {
    setLockLocation(event.target.checked);
  };

  const handleChangeWfh = (event) => {
    setWfh(event.target.checked);
  };

  const handleChangeNotify = (event) => {
    setNotifyEmail(event.taget.checked)
  }


  const finalOvertimeStart = () => {
    if (overtime) {
      if (overtimeStart > 0) {
        return overtimeStart
      }
      return 0
    }
    return 0
  }

  const finalOvertimeStatus = () => {
    if (overtime) {
      if (overtimeStart > 0) {
        return true
      }
      return false
    }
    return false
  }

  const clearFields = (event) => {
    event.preventDefault();
    setName('')
    setRemark('')
    setworkingHours([
      {
        isShiftHour: false,
        startTime: "08:00:00",
        workInMinute: 480,
        breakDurationInMinute: 60
      }
    ])
    setCutOffFrom(1)
    setCutOffTo(31)
    setWfh(false)
    setLockLocation(false)
    setOvertime(false)
    setOvertimeStart(0)
  }

  const handleUpdate = (event) => {
    event.preventDefault()
    const result = {
      name: name,
      remark: remark,
      workHours: workingHours,

      startDate: cutOffFrom,
      endDate: cutOffTo,

      canWorkFromHome: wfh,
      isLockedLocation: lockLocation,

      notificationToEmail: notifyEmail,

      eligible: finalOvertimeStatus(),
      startAfterWorkInMin: finalOvertimeStart()


    };
    dispatch(updateAttendanceById(token, id, result))
    setIsModalOpen(true)

  };
  return (
    <div className={Styles.Container}>
      <form onSubmit={handleUpdate}>
        <div className={Styles.Wrapper}>
          <span> Name & Descriptions</span>
          <div className={Styles.BoxForm}>
            <span>Scheme Name</span>
            <input
              type="text"
              value={name || ""}
              onChange={(e) => setName(e.target.value)}
              style={{ paddingLeft: 5 }}
              name="name"
              required
            />
          </div>
          <div className={Styles.Desc}>
            <span>Descriptions</span>
            <textarea
              value={remark || ""}
              style={{ paddingLeft: 5 }}
              onChange={(e) => setRemark(e.target.value)}
              name="remark"
              required
            />
          </div>
        </div>
        <hr />
        {workingHours?.map((item, index) => {
          return (
            <div key={index}>
              <div className={Styles.WorkingHourHeaderWrapper}>
                <span>Working Time {workingHours.length > 1 && index + 1}</span>
                <div className={Styles.ShiftDeleteOuterWrapper}>
                  <div className={Styles.ShiftDeleteWrapper}>
                    <div className={Styles.BoxRadioShift}>
                      <input onChange={(e) => handleIsShift(e, index)} type="radio" value="normal" name={`isShiftHour` + index} checked={item.isShiftHour === false || false} />
                      <label>
                        Normal Hours
                      </label>
                    </div>
                    <div className={Styles.BoxRadioShift}>
                      <input onChange={(e) => handleIsShift(e, index)} type="radio" value="shift" name={`isShiftHour` + index} checked={item.isShiftHour === true || false} />
                      <label>
                        Shift Hours
                      </label>
                    </div>
                  </div>
                  {index !== 0 && (
                    <img onClick={() => handleDeleteWorkingHour(index)} src={DELETE_ICON} alt="delete" />
                  )}
                </div>
              </div>
              <div>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className={Styles.BoxForm}>
                      <span>Start Time</span>
                      <div className={Styles.BoxInputWrapper}>
                        <span>Hours</span>
                        <TimePicker
                          ampm={false}
                          value={entryHour(item.startTime)}
                          onChange={(value) => handleChangeStartTime(value, index)}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              style={{ width: 105 }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </LocalizationProvider>
                  <div className={Styles.BoxForm}>
                    <span>Working Minutes</span>
                    <div className={Styles.BoxInputWrapper}>
                      <span>Minutes</span>
                      <span className={Styles.BoxInput}>
                        <AccessTime className={Styles.ClockIcon} />
                        <input
                          type="number"
                          value={item.workInMinute || ""}
                          onChange={(e) => handleChangeWorkingMinutes(e, index)}
                          className={Styles.Duration}
                        />
                      </span>

                    </div>
                  </div>
                  <div className={Styles.BoxForm}>
                    <span>Break Minutes</span>
                    <div className={Styles.BoxInputWrapper}>
                      <span>Minutes</span>
                      <span className={Styles.BoxInput}>
                        <AccessTime className={Styles.ClockIcon} />
                        <input
                          type="number"
                          value={item.breakDurationInMinute || ""}
                          onChange={(e) => handleChangeBreakDuration(e, index)}
                          className={Styles.Duration} />                      </span>
                    </div>
                  </div>

                  {
                    index === workingHours.length - 1 && (
                      <Button
                        startIcon={<AddIcon sx={{ fontSize: 20, color: "#1571DE" }} />}
                        onClick={() => handleAddWorkingHour()}>Working Time</Button>
                    )
                  }
                  <hr />

                </div>
              </div>
            </div>

          )
        })}
        <div className={Styles.BodyWrapper}>
          <div className={Styles.OvertimeWrapper}>
            <span>Set Overtime - After</span>
            <div className={Styles.BoxInputWrapper}>
              <AccessTime className={Styles.ClockIcon2} />
              <input
                className={Styles.Duration2}
                type="number"
                onChange={(e) => setOvertimeStart(e.target.value)}
                disabled={!overtime}
                value={overtimeStart || ""}
              />
              <span>Minutes</span>
            </div>
          </div>

          <Switch
            checked={overtime}
            size="large"
            onChange={(e) => setOvertime(e.target.checked)}
          />
        </div>
        <hr />

        <div className={Styles.Divider}>
          <span>Location</span>
          <div className={Styles.BoxForm2}>
            <span>Work From Home</span>
            <Switch checked={wfh} size="large" onChange={handleChangeWfh} />
          </div>
          <div className={Styles.BoxForm2}>
            <span>Lock Location</span>
            <Switch
              checked={lockLocation}
              size="large"
              onChange={handleChangeLockLocation}
            />
          </div>
        </div>

        <hr />

        <div className={Styles.Divider}>
          <span>Cut Off Date</span>
          <div className={Styles.BoxForm2}>
            <span>Set range of payroll for every month</span>
            <div className={Styles.BoxInputWrapper}>
              <span>Every Month - Day</span>
              <input
                value={cutOffFrom || ""}
                onChange={(e) => setCutOffFrom(e.target.value)}
                className={Styles.CutOff}
                type="number"
              />
              <span>to</span>
              <input
                value={cutOffTo || ""}
                onChange={(e) => setCutOffTo(e.target.value)}
                className={Styles.CutOff}
                type="number"
              />
            </div>
          </div>
        </div>



        <hr />

        <div className={Styles.Divider}>
          <div className={Styles.BoxForm2}>
            <span>Notify to email</span>
            <Switch checked={notifyEmail} size="large" onChange={handleChangeNotify} />
          </div>
        </div>

        <div className={Styles.ButtonWrapper}>
          <div className={Styles.SaveButton}>
            <button>Save Scheme</button>
          </div>
          <div onClick={clearFields} className={Styles.ClearButton}>
            <button>Clear All</button>
          </div>
        </div>
      </form>
      {isModalOpen && (
        <ModalAttendanceSASuccess active={isModalOpen} onOpen={setIsModalOpen} desc={`${id ? "One Scheme updated successfully " : "One Scheme added to the Loan"}`} />
      )}
    </div>
  );
}
